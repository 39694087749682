<template>
  <v-dialog v-model="mostrar" max-width="500" persistent>
    <v-card class="elevation-12">
      <v-toolbar dense flat><h3>Ooops! Esto es incómodo 😥</h3> </v-toolbar>
      <v-card-text>
        <div class="text--primary" style="padding-top: 10px">
          Ocurrió un error al activar la cuenta. Por favor intenta de nuevo y si
          el problema persiste con gusto te atendemos en el área de soporte.
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          type="submit"
          color="primary"
          dark
          small
          @click="$emit('redireccionar')"
          >Aceptar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "errorActivacion",
  props: ["mostrar"],
};
</script>
