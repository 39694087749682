import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"500","persistent":""},model:{value:(_vm.mostrar),callback:function ($$v) {_vm.mostrar=$$v},expression:"mostrar"}},[_c(VCard,{staticClass:"elevation-12"},[_c(VToolbar,{attrs:{"dense":"","flat":""}},[_c('h3',[_vm._v("Ooops! Esto es incómodo 😥")])]),_c(VCardText,[_c('div',{staticClass:"text--primary",staticStyle:{"padding-top":"10px"}},[_vm._v(" Ocurrió un error al activar la cuenta. Por favor intenta de nuevo y si el problema persiste con gusto te atendemos en el área de soporte. ")])]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"type":"submit","color":"primary","dark":"","small":""},on:{"click":function($event){return _vm.$emit('redireccionar')}}},[_vm._v("Aceptar")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }