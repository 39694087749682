<template>
  <v-dialog v-model="mostrar" max-width="500" persistent>
    <v-card class="elevation-12">
      <v-toolbar dense flat
        ><h3>Cuenta activada correctamente 😎</h3>
        </v-toolbar
      >
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          type="submit"
          color="primary"
          dark
          small
          @click="$emit('redireccionar')"
          >Aceptar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "cuentaActivada",
  props: ["mostrar"]
};
</script>