<template>
  <v-app style="background-color: #b0bec5">
    <v-main>
      <v-dialog v-if="loading" persistent v-model="loading" max-width="300">
        <v-card>
          <v-toolbar dense color="primary" dark>Activando cuenta... </v-toolbar>
          <v-progress-linear color="white" indeterminate></v-progress-linear>
        </v-card>
      </v-dialog>

      <cuentaActivadaComponent
        v-if="cuentaActivada"
        :mostrar="cuentaActivada"
        @redireccionar="redireccionar"
      />

      <errorActivacionComponent
        v-if="errorActivacion"
        :mostrar="errorActivacion"
        @redireccionar="redireccionar"
      />
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import cuentaActivadaComponent from "./cuentaActivada.vue";
import errorActivacionComponent from "./errorActivacion.vue";
import { activarCuentaService } from "./login.service";

export default {
  name: "activarCuentaView",
  components: { cuentaActivadaComponent, errorActivacionComponent },
  computed: {
    ...mapGetters(["appName", "redirect"]),
  },
  data() {
    return {
      loading: true,
      idUsuario: null,
      cuentaActivada: false,
      errorActivacion: false,
    };
  },
  async created() {
    this.idUsuario = this.$route.params.idUsuario;
    this.activarCuenta(this.idUsuario);
  },
  methods: {
    async activarCuenta(idUsuario) {
      try {
        const serverResponse = await activarCuentaService(idUsuario);
        if (!serverResponse.ok) this.errorActivacion = true;
        else this.cuentaActivada = true;
      } catch (error) {
        if (!error.handdled) this.errorActivacion = true;
      }
    },
    redireccionar() {
      this.$router.push("/login");
    },
  },
};
</script>
